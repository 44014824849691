// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-letmeknow-js": () => import("./../../../src/pages/letmeknow.js" /* webpackChunkName: "component---src-pages-letmeknow-js" */),
  "component---src-pages-oraclemidisgood-js": () => import("./../../../src/pages/oraclemidisgood.js" /* webpackChunkName: "component---src-pages-oraclemidisgood-js" */)
}

